import {
  CustomAccount,
  createCustomTransactions,
  validateBills,
  getBills,
  createMatchingCustomTransactionsFromBills,
  BusinessIndustry,
} from './importDummyDataHelpers'
import { ImportForm } from './ImportForm'
import React from 'react'
import { LAYER_API_URL } from '../../Root'

export interface ImportBillsProps {
  demoName: string
  businessId: string
  industry: BusinessIndustry
  bearerToken: string
  defaultCustomAccount: CustomAccount
}

export const ImportBills = ({
  demoName,
  businessId,
  industry,
  bearerToken,
  defaultCustomAccount,
}: ImportBillsProps) => {
  return (
    <ImportForm
      title='Bills'
      defaultCustomAccount={defaultCustomAccount}
      getInitialData={() => getBills(demoName)}
      validateData={validateBills}
      onSubmit={async bills => {
        // Create bills first
        const billResponse = await fetch(
          `${LAYER_API_URL}/v1/businesses/${businessId}/bills/bulk`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${bearerToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(bills),
          },
        )
        if (!billResponse.ok) {
          throw new Error('Bill API call failed')
        }

        // Then create matching transactions
        const matchingTxns = await createMatchingCustomTransactionsFromBills(
          bills,
          demoName,
        )
        await createCustomTransactions(
          businessId,
          bearerToken,
          defaultCustomAccount.id,
          matchingTxns,
        )
      }}
      dependencies={[demoName]}
    />
  )
}
