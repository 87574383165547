import React from 'react'

import { PlatformOnboarding } from '@layerfi/components'
import { useNavigate } from 'react-router-dom'
import './styles.css'

export function OnboardingPage() {
  const navigate = useNavigate()

  return (
    <div className='OnboardingPage'>
      <div className='LinkAccountsContainer'>
        <PlatformOnboarding
          onComplete={() => navigate('../bank-transactions')}
        />
      </div>
    </div>
  )
}
