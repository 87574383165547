import {
  BusinessIndustry,
  createCustomTransactions,
  CustomAccount,
  getTransferCustomTransactions,
  getTransferDepositoryCustomTransactions,
  validateCustomTransactions,
} from './importDummyDataHelpers'
import { ImportForm } from './ImportForm'
import React from 'react'

export interface ImportTransfersProps {
  demoName: string
  businessId: string
  industry: BusinessIndustry
  bearerToken: string
  defaultCustomAccount: CustomAccount
  defaultCustomDepositoryAccount: CustomAccount
}

export const ImportTransfers = ({
  demoName,
  businessId,
  industry,
  bearerToken,
  defaultCustomAccount,
  defaultCustomDepositoryAccount,
}: ImportTransfersProps) => {
  return (
    <ImportForm
      title='Account Transfers'
      defaultCustomAccount={defaultCustomAccount}
      getInitialData={() => getTransferCustomTransactions(demoName)}
      validateData={validateCustomTransactions}
      onSubmit={async transactions => {
        // Create transactions in default custom account
        await createCustomTransactions(
          businessId,
          bearerToken,
          defaultCustomAccount.id,
          transactions,
        )
        // Create matching transactions in depository account
        const depositoryTransactions = getTransferDepositoryCustomTransactions(
          transactions,
          demoName,
        )
        await createCustomTransactions(
          businessId,
          bearerToken,
          defaultCustomDepositoryAccount.id,
          depositoryTransactions,
        )
      }}
      dependencies={[demoName]}
    />
  )
}
