import React from 'react'
import { Reports as ReportsView } from '@layerfi/components'
import { AccountingLayout } from '../../../layouts/AccountingLayout'
import { useOutletContext } from 'react-router-dom'

export const Reports = () => {
  const { useComparisonPnl, demoName } = useOutletContext<{
    useComparisonPnl: boolean
    demoName: string
  }>()

  const nyTagFilter = {
    displayName: 'New York',
    tagFilterConfig: {
      tagFilters: {
        structure: 'DEFAULT',
        tagKey: 'location',
        tagValues: ['new york'],
      },
    },
  }

  const sfTagFilter = {
    displayName: 'San Francisco',
    tagFilterConfig: {
      tagFilters: {
        structure: 'DEFAULT',
        tagKey: 'location',
        tagValues: ['san francisco'],
      },
    },
  }

  const laTagFilter = {
    displayName: 'Los Angeles',
    tagFilterConfig: {
      tagFilters: {
        structure: 'DEFAULT',
        tagKey: 'location',
        tagValues: ['los angeles'],
      },
    },
  }

  const allLocationsTagFilter = {
    displayName: 'All Locations',
    tagFilterConfig: {
      structure: 'DEFAULT',
      tagFilters: 'None' as const,
    },
  }

  const tagComparisonConfig = {
    tagComparisonOptions: [
      nyTagFilter,
      sfTagFilter,
      laTagFilter,
      allLocationsTagFilter,
    ],
    defaultTagFilter: allLocationsTagFilter,
  }

  const prop1TagFilter = {
    displayName: '524 Clarendon LLC',
    tagFilterConfig: {
      tagFilters: {
        structure: 'DEFAULT',
        tagKey: 'property',
        tagValues: ['524 clarendon llc'],
      },
    },
  }

  const prop2TagFilter = {
    displayName: 'Bay Bridge Investments LLC',
    tagFilterConfig: {
      tagFilters: {
        structure: 'DEFAULT',
        tagKey: 'property',
        tagValues: ['bay bridge investments, llc'],
      },
    },
  }

  const allPropertiesTagFilter = {
    displayName: 'All Properties',
    tagFilterConfig: {
      structure: 'DEFAULT',
      tagFilters: {
        tagKey: 'property',
        tagValues: [],
      },
    },
  }

  const releaseTagComparisonConfig = {
    tagComparisonOptions: [
      prop1TagFilter,
      prop2TagFilter,
      allPropertiesTagFilter,
    ],
    defaultTagFilter: allPropertiesTagFilter,
  }

  const comparisonConfig =
    useComparisonPnl || demoName == 'Impersonate'
      ? demoName == 'Release'
        ? releaseTagComparisonConfig
        : tagComparisonConfig
      : undefined

  return (
    <AccountingLayout>
      <ReportsView
        profitAndLossConfig={{
          allowedDatePickerModes: ['monthPicker', 'dayRangePicker'],
        }}
        comparisonConfig={comparisonConfig}
        statementOfCashFlowConfig={{
          allowedDatePickerModes: ['monthPicker', 'dayRangePicker'],
        }}
      />
    </AccountingLayout>
  )
}
