import {
  createCustomTransactions,
  CustomAccount,
  getManualEntriesForPayroll,
  createMatchingCustomTransactionsFromManualEntries,
  validateManualEntries,
  BusinessIndustry,
} from './importDummyDataHelpers'
import { ImportForm } from './ImportForm'
import React from 'react'
import { LAYER_API_URL } from '../../Root'

export interface ImportPayrollManualEntriesProps {
  demoName: string
  businessId: string
  industry: BusinessIndustry
  bearerToken: string
  defaultCustomAccount: CustomAccount
  customAccountLedgerAccountId: string
}

export const ImportPayrollManualEntries = ({
  demoName,
  businessId,
  industry,
  bearerToken,
  defaultCustomAccount,
  customAccountLedgerAccountId,
}: ImportPayrollManualEntriesProps) => {
  return (
    <ImportForm
      title='Payroll Manual Entries'
      defaultCustomAccount={defaultCustomAccount}
      getInitialData={() =>
        getManualEntriesForPayroll(customAccountLedgerAccountId, businessId)
      }
      validateData={validateManualEntries}
      onSubmit={async manualEntries => {
        // Create manual entries
        for (const entry of manualEntries) {
          const manualEntriesResponse = await fetch(
            `${LAYER_API_URL}/v1/businesses/${businessId}/ledger/journal-entries`,
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(entry),
            },
          )
          if (!manualEntriesResponse.ok) {
            throw new Error(
              `Manual entry creation failed: ${manualEntriesResponse.status}`,
            )
          }
        }

        // Create matching custom transactions
        const customTransactions =
          createMatchingCustomTransactionsFromManualEntries(
            businessId,
            manualEntries,
          )
        await createCustomTransactions(
          businessId,
          bearerToken,
          defaultCustomAccount.id,
          customTransactions,
        )
      }}
      dependencies={[demoName]}
    />
  )
}
