import { formatISO } from 'date-fns'
import {
  LAYER_API_URL,
  DEFAULT_ACCOUNT_NAME,
  DEFAULT_DEPOSITORY_ACCOUNT_NAME,
} from '../../Root'

export enum BusinessIndustry {
  GENERIC = 'GENERIC',
  TRUCKING = 'TRUCKING',
  DEMO_TRADES = 'DEMO_TRADES',
  MEDSPA = 'MEDSPA',
  FLORIST = 'FLORIST',
  NAV_TRUCKING = 'NAV_TRUCKING',
  NAV_BEAUTY_PERSONAL = 'NAV_BEAUTY_PERSONAL',
  MULTI_FAMILY_REAL_ESTATE = 'MULTI_FAMILY_REAL_ESTATE',
  MARLO_SHIPPING = 'MARLO_SHIPPING',
  MOEGO_PET_CARE = 'MOEGO_PET_CARE',
  JOBBER_HOME_SERVICES = 'JOBBER_HOME_SERVICES',
  KARAT_CREATOR = 'KARAT_CREATOR',
  SESO_AGRICULTURE_LABOR = 'SESO_AGRICULTURE_LABOR',
}

export type Business = {
  id: string
  type: 'Business'
  externalId: string
  legalName: string
  entityType: string
  phoneNumber: string
  naicsCode: string
  tin: string
  importedAt: string
  updatedAt: string
  usState: string
  country: string
  sms_enabled: boolean
  sms_stopped: boolean
  industry: BusinessIndustry
}

// Bank Transaction Types
export type CategorizationStatus = 'READY_FOR_INPUT' | 'CATEGORIZED' | 'PENDING'
export type ProjectedIncomeCategory = 'EXPENSE' | 'REVENUE'

// Account type from suggested match
export type Account = {
  type: 'Account'
  id: string
  stable_name: string
  category: string
  display_name: string
  description: string
}

// Exclusion type for personal transactions
export type Exclusion = {
  type: 'Exclusion'
  id: string
  category: 'PERSONAL_EXPENSES' | 'PERSONAL_INFLOWS'
  display_name: string
}

// Categorization Flow
export type CategorizationFlowSuggestion = Account | Exclusion

export type CategorizationFlow = {
  type: 'ASK_FROM_SUGGESTIONS'
  suggestions: CategorizationFlowSuggestion[]
}

// Adjustment type for matches
export type Adjustment = {
  amount: number
  account: AccountId
  description: string
}

// Invoice Match type
export type InvoiceMatch = {
  type: 'Invoice_Match'
  id: string
  amount: number
  date: string
  description: string
  adjustment: Adjustment | null
}

// Bill Match type
export type BillMatch = {
  type: 'Bill_Match'
  id: string
  amount: number
  date: string
  description: string
  adjustment: Adjustment | null
}

// Transfer Match type
export type TransferMatch = {
  type: 'Transfer_Match'
  id: string
  amount: number
  date: string
  description: string
  fromAccountName: string
  toAccountName: string
  adjustment: Adjustment | null
}

// Suggested Match types
export type SuggestedMatchType = 'INVOICE_PAYMENT' | 'BILL_PAYMENT' | 'TRANSFER'

export type MatchDetails = InvoiceMatch | BillMatch | TransferMatch

export type SuggestedMatch = {
  id: string
  matchType: SuggestedMatchType
  details: MatchDetails
}

export type BankTransaction = {
  type: 'Bank_Transaction'
  id: string
  business_id: string
  source: 'CUSTOM' | 'PLAID' | 'STRIPE' | 'UNIT'
  source_transaction_id: string
  source_account_id: string
  imported_at: string
  date: string
  direction: BankTransactionDirection
  amount: number
  counterparty_name: string
  description: string
  account_name: string
  categorization_status: CategorizationStatus
  category: Category | null
  categorization_method: string | null
  categorization_flow: CategorizationFlow
  categorization_ux?: any
  projected_income_category: ProjectedIncomeCategory
  suggested_matches: SuggestedMatch[]
  match: any | null
  transaction_tags: string[]
  document_ids: string[]
  tasks: any[]
}

type Payment = {
  external_id?: string
  method: string
  fee: number
  amount: number
}

type InvoiceLineItem = {
  description: string
  product: string
  unit_price: number
  quantity: number
}

export type TransactionMatch = {
  transaction_id: string
  suggested_match_id: string
}

export type Invoice = {
  external_id?: string
  sent_at: string
  recipient_name: string
  line_items: InvoiceLineItem[]
  payments: Payment[]
}

export type PayoutPayment = {
  invoice_payment_external_id: string
  amount: number
}

export type PayoutParams = {
  external_id: string
  paid_out_amount: number
  fee: number
  processor: string
  completed_at: string
  payments: PayoutPayment[]
}

export type CustomTransactionParams = {
  external_id: string
  direction: BankTransactionDirection
  description: string
  amount: number
  date: string
  merchant_name: string
  currency_code: 'USD'
}

export type ExternalAccountSource = 'PLAID' | 'STRIPE' | 'CUSTOM' | 'UNIT'

export type ExternalAccount = {
  id: string
  external_account_source: ExternalAccountSource
  external_account_name: string
  external_account_external_id: string
  ledger_account_id: string
}

export type BankTransactionDirection = 'DEBIT' | 'CREDIT'

export type AccountType = 'depository' | 'credit'

export type AccountSubType = 'checking' | 'credit card'

export type CustomAccount = {
  id: string
  mask: string
  account_name: string
  account_type: AccountType
  account_subtype: AccountSubType
  ledger_account_id: string
}

export type AccountId = {
  type: 'AccountId'
  id: string
}

export type AcconutStableName = {
  type: 'StableName'
  stable_name: string
}

export type AccountIdentifier = AccountId | AcconutStableName

export type Category = {
  type: 'Category'
  category: AccountIdentifier
}

export type CreateManualEntryLineItem = {
  account_identifier: AccountIdentifier
  amount: number
  direction: BankTransactionDirection
}

export type CreateManualEntry = {
  external_id?: string
  entry_at: string
  created_by: string
  memo: string
  line_items: CreateManualEntryLineItem[]
}

export const getBusiness = async (
  businessId: string,
  bearerToken: string,
): Promise<Business> => {
  const response = await fetch(`${LAYER_API_URL}/v1/businesses/${businessId}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
  })
  const responseData = await response.json()
  return responseData.data as Business
}

export const getAccount = async (
  businessId: string,
  bearerToken: string,
  accountId: string,
): Promise<ExternalAccount> => {
  const response = await fetch(
    `${LAYER_API_URL}/v1/businesses/${businessId}/external-accounts/${accountId}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    },
  )
  const responseData = await response.json()
  return responseData.data as ExternalAccount
}

export const getAccounts = async (
  businessId: string,
  bearerToken: string,
): Promise<ExternalAccount[]> => {
  const response = await fetch(
    `${LAYER_API_URL}/v1/businesses/${businessId}/external-accounts`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    },
  )
  const responseData = await response.json()
  const externalAccounts = responseData.data
    .external_accounts as ExternalAccount[]
  return externalAccounts
}

export const getCustomAccountById = async (
  businessId: string,
  bearerToken: string,
  customAccountId: string,
) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
  }
  const responseData = await fetch(
    `${LAYER_API_URL}/v1/businesses/${businessId}/custom-accounts/${customAccountId}`,
    options,
  ).then(response => response.json())
  return responseData.data as CustomAccount
}

export const createCustomAccount = async (
  businessId: string,
  bearerToken: string,
  externalId: string,
  mask: string,
  accountName: string,
  institutionName: string,
  accountType: AccountType,
  accountSubType: AccountSubType,
) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    body: `{"external_id":"${externalId}","mask":"${mask}","account_name":"${accountName}","institution_name":"${institutionName}","account_type":"${accountType}","account_subtype":"${accountSubType}"}`,
  }
  const responseData = await fetch(
    `${LAYER_API_URL}/v1/businesses/${businessId}/custom-accounts/`,
    options,
  ).then(response => response.json())
  return responseData.data as CustomAccount
}

export const getOrCreateDefaultCustomAccount = async (
  businessId: string,
  bearerToken: string,
): Promise<CustomAccount> => {
  const accounts = await getAccounts(businessId, bearerToken)
  const defaultAccount = accounts.find(account => {
    return (
      account.external_account_source == 'CUSTOM' &&
      account.external_account_name == DEFAULT_ACCOUNT_NAME &&
      account.external_account_external_id == businessId
    )
  })
  if (defaultAccount != undefined) {
    const customAccount = await getCustomAccountById(
      businessId,
      bearerToken,
      defaultAccount.id,
    )
    return customAccount
  }

  const createdDefaultAccount = await createCustomAccount(
    businessId,
    bearerToken,
    businessId, // using the business ID as an external ID for the default custom account
    '4321',
    DEFAULT_ACCOUNT_NAME,
    'Layer Demo',
    'credit',
    'credit card',
  )

  return createdDefaultAccount
}

export const getDefaultCustomDepositoryAccount = async (
  businessId: string,
  bearerToken: string,
): Promise<CustomAccount | undefined> => {
  const externalId = `${businessId}-depository`
  const accounts = await getAccounts(businessId, bearerToken)
  const defaultAccount = accounts.find(account => {
    return (
      account.external_account_source == 'CUSTOM' &&
      account.external_account_name == DEFAULT_DEPOSITORY_ACCOUNT_NAME &&
      account.external_account_external_id == externalId
    )
  })
  if (defaultAccount != undefined) {
    const customAccount = await getCustomAccountById(
      businessId,
      bearerToken,
      defaultAccount.id,
    )
    return customAccount
  }
}

export const getOrCreateDefaultCustomDepositoryAccount = async (
  businessId: string,
  bearerToken: string,
): Promise<CustomAccount> => {
  const externalId = `${businessId}-depository`
  const defaultAccount = await getDefaultCustomDepositoryAccount(
    businessId,
    bearerToken,
  )
  if (defaultAccount !== undefined) return defaultAccount

  const createdDefaultAccount = await createCustomAccount(
    businessId,
    bearerToken,
    externalId,
    '6789',
    DEFAULT_DEPOSITORY_ACCOUNT_NAME,
    'Layer Demo',
    'depository',
    'checking',
  )

  return createdDefaultAccount
}

export const createCustomTransactions = async (
  businessId: string,
  bearerToken: string,
  customAccountId: string,
  customTransactionParams: CustomTransactionParams[],
) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    body: `{"transactions": ${JSON.stringify(customTransactionParams)}}`,
  }
  const responseData = await fetch(
    `${LAYER_API_URL}/v1/businesses/${businessId}/custom-accounts/${customAccountId}/transactions`,
    options,
  ).then(response => response.json())
  console.log(responseData)
  return
}

const validateInvoiceLineItem = (item: any): item is InvoiceLineItem => {
  return (
    typeof item.description === 'string' &&
    typeof item.product === 'string' &&
    typeof item.unit_price === 'number' &&
    typeof item.quantity === 'number'
  )
}

const validatePayment = (payment: any): payment is Payment => {
  return (
    typeof payment.method === 'string' &&
    typeof payment.fee === 'number' &&
    typeof payment.amount === 'number'
  )
}

export const validateInvoices = (data: any): data is Invoice[] => {
  return (
    Array.isArray(data) &&
    data.every(invoice => {
      return (
        typeof invoice.sent_at === 'string' &&
        typeof invoice.recipient_name === 'string' &&
        Array.isArray(invoice.line_items) &&
        invoice.line_items.every(validateInvoiceLineItem) &&
        Array.isArray(invoice.payments) &&
        invoice.payments.every(validatePayment)
      )
    })
  )
}

export const validatePayoutParams = (payout: any): payout is PayoutParams => {
  if (!payout || typeof payout !== 'object') return false

  const hasValidBasicProps =
    typeof payout.external_id === 'string' &&
    typeof payout.paid_out_amount === 'number' &&
    typeof payout.fee === 'number' &&
    typeof payout.processor === 'string' &&
    typeof payout.completed_at === 'string'

  if (!hasValidBasicProps) return false

  if (!Array.isArray(payout.payments)) return false

  return payout.payments.every(
    (payment: any) =>
      typeof payment === 'object' &&
      payment !== null &&
      typeof payment.invoice_payment_external_id === 'string' &&
      typeof payment.amount === 'number',
  )
}

export const validatePayoutParamsList = (
  payouts: any,
): payouts is PayoutParams[] => {
  return (
    Array.isArray(payouts) &&
    payouts.every(payout => {
      return validatePayoutParams(payout)
    })
  )
}

export const validateCustomTransactions = (
  data: any,
): data is CustomTransactionParams[] => {
  return (
    Array.isArray(data) &&
    data.every(transaction => {
      return (
        typeof transaction.external_id === 'string' &&
        (transaction.direction === 'DEBIT' ||
          transaction.direction === 'CREDIT') &&
        typeof transaction.description === 'string' &&
        typeof transaction.amount === 'number' &&
        typeof transaction.date === 'string' &&
        typeof transaction.merchant_name === 'string' &&
        transaction.currency_code === 'USD'
      )
    })
  )
}

const WEEKLY_AVERAGE_PAYOUT = 5000_00

const randomInRange = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const past12Months = (): Array<[number, number]> => {
  const currentDate = new Date()
  let year = currentDate.getFullYear()
  let month = currentDate.getMonth() + 1
  const monthsList: Array<[number, number]> = []
  for (let i = 0; i < 12; i++) {
    monthsList.push([year, month])
    if (month === 1) {
      month = 12
      year -= 1
    } else {
      month -= 1
    }
  }
  return monthsList.reverse()
}

const randomDate = (year: number, month: number): Date => {
  const date = new Date(year, month, 0)
  const lastDayOfMonth = date.getDate()
  const randomDay = Math.floor(Math.random() * lastDayOfMonth) + 1
  return new Date(year, month - 1, randomDay, 15, 46, 54, 255)
}

const past12MonthsFridays = (): Date[] => {
  const currentDate = new Date()
  currentDate.setHours(12, 0, 0, 0)
  let year = currentDate.getFullYear()
  let month = currentDate.getMonth() + 1
  let fridaysList: Date[] = []
  function findFridays(year: number, month: number): Date[] {
    const fridays: Date[] = []
    const daysInMonth = new Date(year, month, 0).getDate()

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month - 1, day, 12, 0)
      if (date.getDay() === 5) {
        fridays.push(date)
      }
    }
    return fridays
  }
  for (let i = 0; i < 12; i++) {
    fridaysList = fridaysList.concat(findFridays(year, month))
    if (month === 1) {
      month = 12
      year -= 1
    } else {
      month -= 1
    }
  }
  return fridaysList
    .filter(friday => friday <= currentDate)
    .reverse()
    .slice(1)
}

export const oneDayMonthlyPast12Months = (dayOfWeek: number): Date[] => {
  const dates: Date[] = []
  const currentDate = new Date()
  let year = currentDate.getFullYear()
  let month = currentDate.getMonth() + 1

  for (let i = 0; i < 12; i++) {
    // Get the third Wednesday (dayOfWeek = 3) of the month
    const firstDay = new Date(year, month - 1, 1)
    let targetDayCount = 0
    const currentDay = new Date(firstDay)

    while (targetDayCount < 3) {
      if (currentDay.getDay() === dayOfWeek) {
        targetDayCount++
        if (targetDayCount === 3) {
          dates.push(new Date(currentDay))
          break
        }
      }
      currentDay.setDate(currentDay.getDate() + 1)
    }

    if (month === 1) {
      month = 12
      year -= 1
    } else {
      month -= 1
    }
  }

  return dates.filter(date => date <= currentDate).reverse()
}

export const getInvoices = (demoName: string): Invoice[] => {
  const invoices: Invoice[] = []
  const recipientNameBase = `${demoName} `
  const pastFridays = past12MonthsFridays()
  pastFridays.forEach((transactionDate, idx) => {
    const transactionAmount = Math.round(
      (WEEKLY_AVERAGE_PAYOUT * randomInRange(50, 150)) / 100,
    )
    const payment: Payment = {
      method: 'ACH',
      fee: 0,
      amount: transactionAmount,
    }

    const lineItem: InvoiceLineItem = {
      description: 'Widget sales',
      product: 'Widgets',
      unit_price: transactionAmount,
      quantity: 1,
    }

    const invoice: Invoice = {
      external_id: `${demoName}-invoice-${idx}`,
      sent_at: formatISO(transactionDate),
      recipient_name:
        recipientNameBase + randomInRange(10000, 100000).toString(),
      line_items: [lineItem],
      payments: [payment],
    }

    invoices.push(invoice)
  })

  return invoices
}

export const getInvoicesForPayouts = (demoName: string): Invoice[] => {
  const invoices: Invoice[] = []
  const monthlyDates = oneDayMonthlyPast12Months(5)

  monthlyDates.forEach((date, index) => {
    const transactionAmount = Math.round(
      (WEEKLY_AVERAGE_PAYOUT * randomInRange(80, 120)) / 100,
    )

    const payment1amount = Math.round(transactionAmount / 2)
    const payment1: Payment = {
      external_id: `payout-payment-${demoName}-${index}-1`,
      method: 'ACH',
      fee: Math.round(payment1amount * 0.029 + 30), // Example fee calculation
      amount: payment1amount,
    }

    const payment2amount = transactionAmount - payment1amount
    const payment2: Payment = {
      external_id: `payout-payment-${demoName}-${index}-2`,
      method: 'ACH',
      fee: Math.round(payment2amount * 0.029 + 30), // Example fee calculation
      amount: payment2amount,
    }

    const lineItem: InvoiceLineItem = {
      description: 'Monthly subscription',
      product: 'Enterprise Plan',
      unit_price: transactionAmount,
      quantity: 1,
    }

    const invoice: Invoice = {
      external_id: `payout-invoice-${demoName}-${index}`,
      sent_at: formatISO(date),
      recipient_name: `payout-invoice-${demoName}-${index}`,
      line_items: [lineItem],
      payments: [payment1, payment2],
    }

    invoices.push(invoice)
  })

  return invoices
}

export const getPayouts = (
  demoName: string,
  invoices: Invoice[],
): PayoutParams[] => {
  const payouts = invoices.map((invoice, index) => {
    // Validate that all payments have external IDs
    invoice.payments.forEach((payment, paymentIndex) => {
      if (!payment.external_id) {
        throw new Error(
          `Invoice ${index}, payment ${paymentIndex} does not have an external_id`,
        )
      }
    })

    // Create a payout that matches exactly the sum of its payment amounts
    const payoutPayments = invoice.payments.map(payment => ({
      invoice_payment_external_id: payment.external_id!,
      amount: payment.amount - payment.fee,
    }))

    // Calculate totals based on the actual payment amounts
    const totalAmount = payoutPayments.reduce(
      (sum, payment) => sum + payment.amount,
      0,
    )
    const totalFees = invoice.payments.reduce(
      (sum, payment) => sum + payment.fee,
      0,
    )

    return {
      external_id: `payout-${demoName}-${index}`,
      paid_out_amount: totalAmount - totalFees,
      fee: totalFees,
      processor: 'PAYPAL',
      completed_at: invoice.sent_at,
      payments: payoutPayments,
    }
  })

  return payouts
}

// Specialized function for SESO_AGRICULTURE_LABOR industry
export const getExpenseCustomTransactionsSeso = (
  demoName: string,
): CustomTransactionParams[] => {
  const externalIdBase = `12345-${demoName}-custom-expense-`
  let itr = 1
  const transactions: CustomTransactionParams[] = []
  const today = new Date()

  const baseTransaction = {
    direction: 'DEBIT' as BankTransactionDirection,
    currency_code: 'USD' as const,
  }

  // SESO-specific transaction templates based on the CSV
  const sesoTransactions = [
    // Chevron - $50 x 4 with variation
    {
      description: 'Chevron',
      merchant_name: 'Chevron',
      base_amount: 5000, // $50.00
      count_per_month: 4,
    },
    // Gas and Electric - $560 x 1
    {
      description: 'Gas and Electric',
      merchant_name: 'Gas and Electric',
      base_amount: 56000, // $560.00
      count_per_month: 1,
    },
    // UPS - $1,000 x 2
    {
      description: 'UPS',
      merchant_name: 'UPS',
      base_amount: 100000, // $1,000.00
      count_per_month: 2,
    },
    // Taylor's Catering - $150 x 4
    {
      // eslint-disable-next-line quotes
      description: "Taylor's Catering",
      // eslint-disable-next-line quotes
      merchant_name: "Taylor's Catering",
      base_amount: 15000, // $150.00
      count_per_month: 4,
    },
    // AT&T - $750 x 1
    {
      description: 'AT&T',
      merchant_name: 'AT&T',
      base_amount: 75000, // $750.00
      count_per_month: 1,
    },
    // Office Depot - $300 x 1
    {
      description: 'Office Depot',
      merchant_name: 'Office Depot',
      base_amount: 30000, // $300.00
      count_per_month: 1,
    },
  ]

  for (const [year, month] of past12Months()) {
    // Standard expenses for continuity - just rent and insurance
    transactions.push({
      ...baseTransaction,
      external_id: `${externalIdBase}${itr}`,
      description: 'ACH DEBIT RENT ABC PROPERTY MGMT',
      amount: 250000,
      date: formatISO(new Date(year, month - 1, 1, 12, 0)),
      merchant_name: 'ACH DEBIT RENT ABC PROPERTY MGMT',
    })
    itr++

    transactions.push({
      ...baseTransaction,
      external_id: `${externalIdBase}${itr}`,
      description: 'State Farm',
      amount: 50000,
      date: formatISO(new Date(year, month - 1, 5, 12, 0)),
      merchant_name: 'State Farm',
    })
    itr++

    // Add SESO-specific transactions
    for (const txnTemplate of sesoTransactions) {
      // Generate specified number of transactions for each type
      for (let i = 0; i < txnTemplate.count_per_month; i++) {
        // Create transaction date - distribute throughout the month
        const day = Math.floor(
          1 + (28 * (i + 1)) / (txnTemplate.count_per_month + 1),
        )
        const txnDate = new Date(year, month - 1, day, 12, 0)

        // Skip future transactions
        if (txnDate > today) continue

        // Add a random variation (90-110% of base amount)
        const amount = Math.round(
          txnTemplate.base_amount * (randomInRange(90, 110) / 100),
        )

        transactions.push({
          ...baseTransaction,
          external_id: `${externalIdBase}${itr}`,
          description: txnTemplate.description,
          amount: amount,
          date: formatISO(txnDate),
          merchant_name: txnTemplate.merchant_name,
        })
        itr++
      }
    }
  }

  return transactions
}

export const getExpenseCustomTransactions = (
  demoName: string,
  industry?: BusinessIndustry,
): CustomTransactionParams[] => {
  // If industry is SESO_AGRICULTURE_LABOR, use the specialized function
  if (industry === BusinessIndustry.SESO_AGRICULTURE_LABOR) {
    return getExpenseCustomTransactionsSeso(demoName)
  }

  // Original implementation for all other industries
  const externalIdBase = `12345-${demoName}-custom-expense-`
  let itr = 1
  const transactions: CustomTransactionParams[] = []
  const today = new Date()

  const baseTransaction = {
    direction: 'DEBIT' as BankTransactionDirection,
    currency_code: 'USD' as const,
  }

  for (const [year, month] of past12Months()) {
    // Rent
    transactions.push({
      ...baseTransaction,
      external_id: `${externalIdBase}${itr}`,
      description: 'ACH DEBIT RENT ABC PROPERTY MGMT',
      amount: 250000,
      date: formatISO(new Date(year, month - 1, 1, 12, 0)),
      merchant_name: 'ACH DEBIT RENT ABC PROPERTY MGMT',
    })
    itr++

    // Utilities
    transactions.push({
      ...baseTransaction,
      external_id: `${externalIdBase}${itr}`,
      description: 'PG&E',
      amount: Math.round(50000 * (randomInRange(80, 120) / 100)),
      date: formatISO(new Date(year, month - 1, 1, 12, 0)),
      merchant_name: 'PG&E',
    })
    itr++

    // Insurance
    transactions.push({
      ...baseTransaction,
      external_id: `${externalIdBase}${itr}`,
      description: 'State Farm',
      amount: 50000,
      date: formatISO(new Date(year, month - 1, 1, 12, 0)),
      merchant_name: 'State Farm',
    })
    itr++

    // Advertisements
    transactions.push({
      ...baseTransaction,
      external_id: `${externalIdBase}${itr}`,
      description: 'Facebook Advertisements',
      amount: Math.round(100000 * (randomInRange(80, 120) / 100)),
      date: formatISO(new Date(year, month - 1, 1, 12, 0)),
      merchant_name: 'Facebook',
    })
    itr++

    // One-off travel
    if (new Date(year, month - 1, 10, 12) < today) {
      transactions.push({
        ...baseTransaction,
        external_id: `${externalIdBase}${itr}`,
        description: 'United Airlines',
        amount: Math.round(50000 * (randomInRange(50, 150) / 100)),
        date: formatISO(new Date(year, month - 1, 10, 12)),
        merchant_name: 'United Airlines',
      })
      itr++

      transactions.push({
        ...baseTransaction,
        external_id: `${externalIdBase}${itr}`,
        description: 'Amazon',
        amount: Math.round(20000 * (randomInRange(50, 150) / 100)),
        date: formatISO(new Date(year, month - 1, 10, 12)),
        merchant_name: 'Amazon',
      })
      itr++
    }

    // Uber (multiple transactions)
    for (let i = 0; i < 5; i++) {
      const uberTransactionDate = randomDate(year, month)
      if (uberTransactionDate < today) {
        transactions.push({
          ...baseTransaction,
          external_id: `${externalIdBase}${itr}`,
          description: 'Uber',
          amount: Math.round(3000 * (randomInRange(25, 200) / 100)),
          date: formatISO(uberTransactionDate),
          merchant_name: 'Uber',
        })
        itr++
      }
    }

    // Other (multiple transactions)
    const merchantNames = ['Chipotle', 'Shell Gas', 'Office Depot', 'Starbucks']
    for (let i = 0; i < 10; i++) {
      const merchantTransactionDate = randomDate(year, month)
      if (merchantTransactionDate < today) {
        const merchantName =
          merchantNames[randomInRange(0, merchantNames.length - 1)]
        transactions.push({
          ...baseTransaction,
          external_id: `${externalIdBase}${itr}`,
          description: merchantName,
          amount: Math.round(8000 * (randomInRange(50, 150) / 100)),
          date: formatISO(merchantTransactionDate),
          merchant_name: merchantName,
        })
        itr++
      }
    }
  }

  return transactions
}

export const getTransferCustomTransactions = (
  demoName: string,
): CustomTransactionParams[] => {
  const transactions: CustomTransactionParams[] = []
  const transferDates = oneDayMonthlyPast12Months(3) // Use Wednesday (3)

  transferDates.forEach((date, index) => {
    const transferAmount = Math.round(5000_00 * (randomInRange(80, 120) / 100))

    transactions.push({
      external_id: `${demoName}-transfer-credit-${index}`,
      direction: 'CREDIT',
      description: 'Payment - Thank You!',
      amount: transferAmount,
      date: formatISO(date),
      merchant_name: 'Transfer',
      currency_code: 'USD',
    })
  })

  return transactions
}

export const getTransferDepositoryCustomTransactions = (
  creditCustomTransactions: CustomTransactionParams[],
  demoName: string,
): CustomTransactionParams[] => {
  return creditCustomTransactions.map((creditTx, index) => ({
    external_id: `${demoName}-transfer-debit-${index}`,
    direction: 'DEBIT', // Opposite of the credit transaction
    description: 'Payment - Thank You!',
    amount: creditTx.amount,
    date: creditTx.date,
    merchant_name: 'Transfer',
    currency_code: 'USD',
  }))
}

export const createMatchingCustomTransactionsFromInvoices = (
  invoices: Invoice[],
  demoName: string,
): CustomTransactionParams[] => {
  let transactionIdCounter = 1
  const matchingTransactions: CustomTransactionParams[] = []
  const externalIdBase = `12345-${demoName}-custom-match-`
  const descriptionBase = 'Widget bulk order #31415-'

  for (const invoice of invoices) {
    for (const payment of invoice.payments) {
      const matchingTransaction: CustomTransactionParams = {
        external_id: `${externalIdBase}${transactionIdCounter}`,
        direction: 'CREDIT',
        description: `${descriptionBase}${transactionIdCounter}`,
        amount: payment.amount,
        date: invoice.sent_at,
        merchant_name: invoice.recipient_name,
        currency_code: 'USD',
      }

      matchingTransactions.push(matchingTransaction)
      transactionIdCounter += 1
    }
  }

  return matchingTransactions
}

export const createMatchingCustomTransactionsFromPayouts = (
  payouts: PayoutParams[],
  demoName: string,
): CustomTransactionParams[] => {
  return payouts.map((payout, index) => ({
    external_id: `${demoName}-payout-match-${index}`,
    direction: 'DEBIT',
    description: `Payout ${payout.external_id}`,
    amount: payout.paid_out_amount,
    date: payout.completed_at,
    merchant_name: `Payout ${index + 1}`,
    currency_code: 'USD',
  }))
}

const validateAccountIdentifier = (
  identifier: any,
): identifier is AccountIdentifier => {
  if (!identifier || typeof identifier !== 'object') {
    return false
  }

  if (identifier.type === 'AccountId') {
    return typeof identifier.id === 'string'
  }

  if (identifier.type === 'StableName') {
    return typeof identifier.stable_name === 'string'
  }

  return false
}

const validateLineItem = (item: any): item is CreateManualEntryLineItem => {
  return (
    typeof item === 'object' &&
    item !== null &&
    typeof item.amount === 'number' &&
    (item.direction === 'DEBIT' || item.direction === 'CREDIT') &&
    validateAccountIdentifier(item.account_identifier)
  )
}

const validateManualEntry = (entry: any): entry is CreateManualEntry => {
  return (
    typeof entry === 'object' &&
    entry !== null &&
    typeof entry.entry_at === 'string' &&
    typeof entry.created_by === 'string' &&
    typeof entry.memo === 'string' &&
    Array.isArray(entry.line_items) &&
    entry.line_items.every(validateLineItem) &&
    (entry.external_id === undefined || typeof entry.external_id === 'string')
  )
}

export const validateManualEntries = (
  payload: any,
): payload is CreateManualEntry[] => {
  return Array.isArray(payload) && payload.every(validateManualEntry)
}

export const getManualEntriesForPayroll = (
  customAccountId: string,
  businessId: string,
): CreateManualEntry[] => {
  const entries: CreateManualEntry[] = []
  const monthsList = past12Months()

  // Base amounts for different payroll categories
  const baseAmounts = {
    PAYROLL_REGULAR_WAGES: 450000, // $4,500 base
    PAYROLL_CONTRACTORS: 250000, // $2,500 base
    PAYROLL_BENEFITS: 120000, // $1,200 base
    PAYROLL_TAXES: 180000, // $1,800 base
    PAYROLL_FEES: 50000, // $500 base
  }

  for (const [year, month] of monthsList) {
    const entryDate = new Date(year, month - 1, 15, 12, 0, 0)
    const today = new Date()
    if (entryDate > today) {
      continue
    }

    const lineItems: CreateManualEntryLineItem[] = []
    let totalDebit = 0

    Object.entries(baseAmounts).forEach(([category, baseAmount]) => {
      const variation = 0.9 + Math.random() * 0.2
      const amount = Math.round(baseAmount * variation)
      totalDebit += amount

      lineItems.push({
        account_identifier: {
          type: 'StableName',
          stable_name: category as any,
        },
        amount,
        direction: 'DEBIT',
      })
    })

    lineItems.push({
      account_identifier: {
        type: 'AccountId',
        id: customAccountId,
      },
      amount: totalDebit,
      direction: 'CREDIT',
    })

    const entry: CreateManualEntry = {
      entry_at: formatISO(entryDate),
      external_id: `payroll-entry-${businessId}-${month}-${year}`,
      created_by: 'Customizable Demo',
      memo: `Payroll for ${entryDate.toLocaleString('default', { month: 'long' })} ${year}`,
      line_items: lineItems,
    }

    entries.push(entry)
  }

  return entries
}

export const createMatchingCustomTransactionsFromManualEntries = (
  businessId: string,
  manualEntries: CreateManualEntry[],
): CustomTransactionParams[] => {
  const transactions: CustomTransactionParams[] = []
  let transactionCounter = 1

  for (const entry of manualEntries) {
    const creditLineItem = entry.line_items.find(
      item => item.direction === 'CREDIT',
    )

    if (creditLineItem) {
      // Create matching transaction for the credit amount
      const transaction: CustomTransactionParams = {
        external_id: `manual-entry-match-${businessId}-${transactionCounter}`,
        direction: 'DEBIT',
        description: entry.memo,
        amount: creditLineItem.amount,
        date: entry.entry_at,
        merchant_name: 'Payroll Provider',
        currency_code: 'USD',
      }

      transactions.push(transaction)
      transactionCounter++
    }
  }

  return transactions
}

export type PaymentMethod =
  | 'CASH'
  | 'CHECK'
  | 'CREDIT_CARD'
  | 'ACH'
  | 'CREDIT_BALANCE'
  | 'OTHER'

// Add these types and functions to importDummyDataHelpers.ts

export type BillLineItem = {
  external_id?: string
  account_identifier?: AccountIdentifier
  description?: string
  product_name: string
  discount_amount: number
  unit_price: number
  quantity?: number
}

export type BillPayment = {
  external_id?: string
  method: PaymentMethod
  amount: number
  processor?: string
}

export type Bill = {
  external_id?: string
  received_at: string
  due_at?: string
  bill_number?: string
  bill_terms?: 'DUE_ON_RECEIPT' | 'NET_10' | 'NET_15' | 'NET_30' | 'NET_60'
  additional_discount?: number
  vendor_id?: string
  vendor_external_id?: string
  line_items: BillLineItem[]
  payments?: BillPayment[]
}

const isBillLineItem = (item: any): item is BillLineItem => {
  return (
    typeof item.product_name === 'string' &&
    typeof item.unit_price === 'number' &&
    (item.external_id === undefined || typeof item.external_id === 'string') &&
    (item.description === undefined || typeof item.description === 'string') &&
    (item.quantity === undefined || typeof item.quantity === 'number') &&
    (item.discount_amount === undefined ||
      typeof item.discount_amount === 'number') &&
    (item.sales_taxes === undefined || Array.isArray(item.sales_taxes))
  )
}

export const validateBills = (data: any): data is Bill[] => {
  return (
    Array.isArray(data) &&
    data.every(bill => {
      return (
        typeof bill.received_at === 'string' &&
        Array.isArray(bill.line_items) &&
        bill.line_items.every(isBillLineItem) &&
        (!bill.payments || Array.isArray(bill.payments)) &&
        (!bill.additional_sales_taxes ||
          Array.isArray(bill.additional_sales_taxes))
      )
    })
  )
}

const calculateBillTotal = (bill: Bill): number => {
  return (
    bill.line_items.reduce((sum, item) => {
      const itemTotal = item.unit_price * (item.quantity || 1)
      const itemDiscount = item.discount_amount || 0
      return sum + itemTotal - itemDiscount
    }, 0) - (bill.additional_discount || 0)
  )
}

export const getBills = (demoName: string): Bill[] => {
  const bills: Bill[] = []
  const monthsList = past12Months()

  monthsList.forEach((month, idx) => {
    // Regular monthly bills
    const [year, monthNum] = month
    const billDate = new Date(year, monthNum - 1, 15) // Bill received on 15th of each month
    const paymentDate = new Date(year, monthNum - 1, 25) // Payment made on 25th of each month

    // Example bill for office supplies
    const officeSuppliesBill: Bill = {
      external_id: `${demoName}-bill-office-${idx}`,
      received_at: formatISO(billDate),
      bill_terms: 'NET_30',
      vendor_external_id: `office-supplier-${demoName}`,
      line_items: [
        {
          product_name: 'Office Supplies',
          description: 'Monthly office supplies',
          unit_price: Math.round(50000 * (randomInRange(80, 120) / 100)),
          quantity: 1,
          discount_amount: 0,
        },
      ],
    }
    // Add payment that matches the bill total
    const officeSuppliesTotal = calculateBillTotal(officeSuppliesBill)
    officeSuppliesBill.payments = [
      {
        external_id: `${demoName}-bill-payment-office-${idx}`,
        method: 'ACH',
        amount: officeSuppliesTotal,
      },
    ]
    bills.push(officeSuppliesBill)

    // Example bill for utilities
    const utilitiesBill: Bill = {
      external_id: `${demoName}-bill-utilities-${idx}`,
      received_at: formatISO(billDate),
      bill_terms: 'NET_15',
      vendor_external_id: `utility-company-${demoName}`,
      line_items: [
        {
          product_name: 'Electricity',
          description: 'Monthly electricity service',
          unit_price: Math.round(75000 * (randomInRange(80, 120) / 100)),
          quantity: 1,
          discount_amount: 0,
        },
        {
          product_name: 'Water',
          description: 'Monthly water service',
          unit_price: Math.round(25000 * (randomInRange(80, 120) / 100)),
          quantity: 1,
          discount_amount: 0,
        },
      ],
    }
    // Add payment that matches the bill total
    const utilitiesTotal = calculateBillTotal(utilitiesBill)
    utilitiesBill.payments = [
      {
        external_id: `${demoName}-bill-payment-utilities-${idx}`,
        method: 'ACH',
        amount: utilitiesTotal,
      },
    ]
    bills.push(utilitiesBill)
  })

  return bills
}

export const createMatchingCustomTransactionsFromBills = (
  bills: Bill[],
  demoName: string,
): CustomTransactionParams[] => {
  const transactions: CustomTransactionParams[] = []

  for (const bill of bills) {
    if (!bill.external_id) {
      throw new Error('Bill missing external_id')
    }

    // Create a matching transaction for each payment
    bill.payments?.forEach(payment => {
      if (!payment.external_id) {
        throw new Error('Payment missing external_id')
      }

      const transaction: CustomTransactionParams = {
        external_id: `${demoName}-bank-tx-${payment.external_id}`,
        direction: 'DEBIT',
        description: `Payment for ${bill.external_id}`,
        amount: payment.amount,
        date: bill.received_at, // In real world this would be the payment date
        merchant_name: `Demo ${payment.external_id.includes('utilities') ? 'Utilities Inc' : 'Office Supplices & Co'}`,
        currency_code: 'USD',
      }

      transactions.push(transaction)
    })
  }

  return transactions
}
