import * as React from 'react'
import { IconSvgProps } from './types'

const Bank = ({ size = 18, ...props }: IconSvgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 18 18'
    fill='none'
    {...props}
    width={size}
    height={size}
  >
    <path
      d='M2.25 6.07863C2.25 5.87409 2.35501 5.68388 2.5281 5.5749L9 1.5L15.4719 5.5749C15.645 5.68388 15.75 5.87409 15.75 6.07863V6.07863C15.75 6.40739 15.4835 6.67389 15.1547 6.67389H2.84526C2.51651 6.67389 2.25 6.40739 2.25 6.07863V6.07863Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3 13H15V15C15 15.5523 14.5523 16 14 16H4C3.44772 16 3 15.5523 3 15L3 13Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.33333 12.7V6.7H4V12.7'
      stroke='currentColor'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M10.6667 12.7V6.7H7.33334V12.7'
      stroke='currentColor'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M14 12.7V6.7H10.6667V12.7'
      stroke='currentColor'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
  </svg>
)

export default Bank
