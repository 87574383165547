import React, { CSSProperties, useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

export const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const auth = useAuth()
  const navigate = useNavigate()

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ clientName: username, password: password }),
      })

      const data = await response.json()

      if (response.ok) {
        auth?.login(data.token)
        navigate(`/${username}`)
      } else {
        alert('Login failed: ' + data.error)
      }
    } catch (error) {
      alert('An error occurred while logging in.')
    }
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const containerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
  }

  const formStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '450px',
    padding: '30px',
    borderRadius: '12px',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
  }

  const inputStyle = {
    marginBottom: '22px',
    padding: '15px',
    width: '420px',
    borderRadius: '6px',
    border: '1.5px solid #ccc',
    fontSize: '21px',
  }

  const passwordContainerStyle: CSSProperties = {
    position: 'relative',
    width: '450px',
    marginBottom: '22px',
    display: 'flex',
    alignItems: 'center',
  }

  const passwordInputStyle = {
    ...inputStyle,
    marginBottom: '0',
    width: '100%',
  }

  const toggleButtonStyle: CSSProperties = {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    color: '#666',
    padding: '5px',
    position: 'absolute',
    right: '15px',
  }

  const buttonStyle: CSSProperties = {
    padding: '15px',
    width: '100%',
    borderRadius: '6px',
    border: 'none',
    backgroundColor: '#2f3037',
    color: 'white',
    cursor: 'pointer',
    fontSize: '24px',
  }

  const titleStyle: CSSProperties = {
    marginBottom: '30px',
    fontSize: '36px',
    color: '#333',
  }

  return (
    <div style={containerStyle}>
      <form onSubmit={handleSubmit} style={formStyle}>
        <h2 style={titleStyle}>Login</h2>
        <input
          type='text'
          value={username}
          onChange={e => setUsername(e.target.value)}
          placeholder='Username'
          style={inputStyle}
        />
        <div style={passwordContainerStyle}>
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder='Password'
            style={passwordInputStyle}
          />
          <button
            type='button'
            onClick={toggleShowPassword}
            style={toggleButtonStyle}
          >
            {showPassword ? 'Hide' : 'Show'}
          </button>
        </div>
        <button type='submit' style={buttonStyle}>
          Login
        </button>
      </form>
    </div>
  )
}
