import React, { useState } from 'react'
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
import { LAYER_API_URL } from '../Root'
import { BusinessIndustry } from './dataImportFlow/importDummyDataHelpers'

interface FormFieldProps {
  label: string
  value: string
  onChangeHandler: (value: string) => void
  options?: string[]
  required?: boolean
}

const FormField = ({
  label,
  value,
  onChangeHandler,
  options,
  required,
}: FormFieldProps) => {
  const inputElement = options ? (
    <select
      value={value}
      onChange={e => onChangeHandler(e.target.value)}
      required={required ?? true}
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  ) : (
    <input
      type='text'
      value={value}
      onChange={e => onChangeHandler(e.target.value)}
      required={required ?? true}
    />
  )

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
      }}
    >
      <label>
        {label}
        {(required ?? true) && (
          <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
        )}
      </label>
      {inputElement}
    </div>
  )
}

const US_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Puerto Rico',
]

const BUSINESS_INDUSTRIES = Object.values(BusinessIndustry).map(value =>
  value.toString(),
)

interface CreateBusinessProps {
  demoName: string
  bearerToken: string
}

export const CreateBusiness = ({
  demoName,
  bearerToken,
}: CreateBusinessProps) => {
  const [externalId, setExternalId] = useState('')
  const [legalName, setLegalName] = useState('')
  const [tin, setTin] = useState<string>('')
  const [usState, setUsState] = useState('California')
  const [entityType, setEntityType] = useState('LLC')
  const [industry, setIndustry] = useState('GENERIC')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [activationAt, setActivationAt] = useState('2023-04-15T14:13:07Z')
  const [plaidProcessorTokens, setPlaidProcessorTokens] = useState<string[]>([])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    // Prepare the data for the POST request to create a business
    const businessData = {
      external_id: externalId || undefined,
      legal_name: legalName || undefined,
      tin: tin || undefined,
      us_state: usState,
      entity_type: entityType,
      industry: industry,
      phone_number: phoneNumber || undefined,
      activation_at: activationAt || undefined,
      plaid_processor_tokens: plaidProcessorTokens.map(id => ({
        processor_token: id.trim(),
      })),
    }

    try {
      // Step 1: Create the Business
      const createResponse = await fetch(`${LAYER_API_URL}/v1/businesses`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bearerToken}`,
        },
        body: JSON.stringify(businessData),
      })

      const createResponseJson = await createResponse.json()
      if (!createResponse.ok) {
        throw new Error(createResponseJson?.errors[0]?.description)
      }
      if (!createResponseJson.data || !createResponseJson.data.id) {
        throw new Error('No business ID returned from creation API')
      }

      const business_id: string = createResponseJson.data.id
      console.log(`Business ID created successfully: ${business_id}`)

      // Step 2: Update the demo with the new business ID
      const updateResponse = await fetch(`/api/demos/${demoName}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientName: demoName,
          businessId: business_id,
        }),
      })

      if (!updateResponse.ok) {
        throw new Error('Failed to update demo')
      }
      const updateResponseJson = await updateResponse.json()
      console.log('Demo updated successfully:', updateResponseJson)
      alert('Business created successfully')
    } catch (error) {
      console.error('Error in creating business or updating demo:', error)
      alert(`Error creating business: ${error}`)
    }
  }

  const generateCurlCommand = (): string => {
    const data = {
      external_id: externalId || undefined,
      legal_name: legalName || undefined,
      tin: tin || undefined,
      us_state: usState,
      entity_type: entityType,
      industry: industry,
      phone_number: phoneNumber || undefined,
      activation_at: activationAt || undefined,
      plaid_processor_tokens: plaidProcessorTokens.map(id => ({
        processor_token: id.trim(),
      })),
    }
    const formattedData = JSON.stringify(data, null, 2)
    return `curl -X POST ${LAYER_API_URL}/v1/businesses \\
    -H "Authorization: Bearer <access_token>" \\
    -H "Content-Type: application/json" \\
    -d '${formattedData.replace(/'/g, "'\\''")}'` // eslint-disable-line quotes
  }

  Prism.highlightAll()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <h1>Create Business</h1>
      <form
        onSubmit={handleSubmit}
        style={{
          width: 400,
          margin: '10px',
          padding: '20px',
          border: '2px solid black',
          borderRadius: '5px',
        }}
      >
        <FormField
          label={'External ID:'}
          value={externalId}
          onChangeHandler={setExternalId}
          required={false}
        />
        <FormField
          label={'Legal Name:'}
          value={legalName}
          onChangeHandler={setLegalName}
        />
        <FormField
          label={'TIN:'}
          value={tin ?? ''}
          onChangeHandler={setTin}
          required={false}
        />
        <FormField
          label={'US State:'}
          value={usState}
          onChangeHandler={setUsState}
          options={US_STATES}
        />
        <FormField
          label={'Entity Type:'}
          value={entityType}
          onChangeHandler={setEntityType}
          options={['SOLE_PROP', 'C_CORP', 'LLC', 'S_CORP', 'PARTNERSHIP']}
        />
        <FormField
          label={'Industry:'}
          value={industry}
          onChangeHandler={setIndustry}
          options={BUSINESS_INDUSTRIES}
        />
        <FormField
          label={'Phone Number:'}
          value={phoneNumber}
          onChangeHandler={setPhoneNumber}
          required={false}
        />
        <FormField
          label={'Activation Date:'}
          value={activationAt}
          onChangeHandler={setActivationAt}
          required={false}
        />
        <FormField
          label={'Plaid Processor Tokens:'}
          value={plaidProcessorTokens.join(',')}
          onChangeHandler={v => setPlaidProcessorTokens(v.split(','))}
          required={false}
        />
        <div style={{ textAlign: 'right' }}>
          <button type='submit'>Create Business</button>
        </div>
      </form>
      <br />
      <pre style={{ fontSize: 12, backgroundColor: '#f5f2f0' }}>
        <code className='language-bash'>{generateCurlCommand()}</code>
      </pre>
    </div>
  )
}
