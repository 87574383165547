import React, { ReactNode } from 'react'

import './styles.css'
import { Nav } from '../../components/Nav'
import { useSidebarContext } from '../../contexts/SidebarContext'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

interface AccountingLayoutProps {
  children: ReactNode
}

export const AccountingLayout = ({ children }: AccountingLayoutProps) => {
  const { openSidebar } = useSidebarContext()
  const { isSidebarHidden, brandLogoUrl } = useOutletContext<{
    isSidebarHidden: boolean
    brandLogoUrl: string
  }>()
  const navigate = useNavigate()
  const auth = useAuth()

  return (
    <div className='accounting-layout'>
      <div className='accounting-layout__bar'>
        <div className='accounting-layout__topbar'>
          <Nav />
          <div className='accounting-layout__topbar__right'>
            {isSidebarHidden ? (
              <div className={'topbar-logo-container'}>
                <img
                  className='brand-logo'
                  src={brandLogoUrl}
                  alt='Client Logo'
                />
                <button
                  className='topbar-logout-button'
                  onClick={() => {
                    auth.logout()
                    navigate('/')
                  }}
                >
                  Log out
                </button>
              </div>
            ) : (
              <button onClick={() => openSidebar(true)}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                >
                  <path
                    d='M2.25 9H15.75'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M2.25 4.5H15.75'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M2.25 13.5H15.75'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
      <div className='accounting-layout__content'>{children}</div>
    </div>
  )
}
